<template>
  <div class="index-index-container">
    <div class="index-index-swiper">
      <div class="index-swiper-item" v-for="(item, index) in swiperList" :key="index" :style="{display:item.isShow ? 'block':'none'}">
        <!-- {{item.content}} -->
        <leave-item :leave="item"></leave-item>
      </div>
    </div>
  </div>
</template>

<script>
import leaveItem from "@/components/Message/leaveItem.vue"; 
import { getDapingShow } from "@/api/remarkMessage";  //getDapingDetail
export default {
  name: 'swiperComponent',
  components: {
    leaveItem
  },
  props: ['topItem'],
  data () {
    return {
      actId: null,
      lastSid: 0,
      list: [],
      swiperId: '',
      swiperList: [],
      timerDaping: null,
      timer: null,
    }
  },
  watch: {
    topItem(){
       this.swiperList.forEach(item => {
          if(this.topItem?.id == item.id) item.isShow = false
          else item.isShow = true
       })
    }
  },
  created () {
    this.actId = this.$route.query.id || 2
    this.getSwiperSetTime()
    this.getRandomItem()
  },
  methods: {
    getSwiperSetTime () {
      this.timer = setInterval(() => {
        if(this.list.length == 0)return
        let index = this.list.findIndex(item => item.id == this.swiperId)
        if (index != -1 && index < this.list.length - 1) {
          this.swiperList.push(this.list[index + 1])
          this.swiperId = this.list[index + 1].id
          //console.log(this.list)
        } else {
          this.swiperList.push(this.list[0])
          this.swiperId = this.list[0].id
        }
        
         
      }, 2000)
    },
    getRandomItem () {
      setInterval(async() => {
        let index = this.list.findIndex(item => item.id == this.swiperId)
        let res = await this.getDapingDetail()
        if(res.isloop == 1){
         let detail = this.list.find(item => item.sid == res.sid)
         !detail && this.list.splice(index == -1 ? 0 : index + 1, 0, res.info)
        }else{
          this.swiperList.push(res.info)
        }
        
      }, 2000)
    },
    //每秒获取一条留言
    getDapingDetail() {
      let _this = this
        return new Promise(reslove => getDapingShow({
            act_id: this.actId,
            last_sid: this.lastSid || 0,
            no_sid: this.topItem ? this.topItem.id : 0
            }).then(res => {
            if (res.status == 200) {
                const info = res.data.info
                res.data.info.isShow = true
                res.data.info.content = decodeURIComponent(res.data.info.content)
                _this.lastSid = info.sid
                reslove(res.data)
            }
        })
      )
    },
    
  },
  onBeforeUnmount(){
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
.index-index-container{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.index-index-swiper{
  min-height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: all 1s;
  .index-swiper-item{
    width: 100%;
    margin: .625rem;
    box-sizing: border-box;
    padding: .625rem;
    color: #ffffff;
    word-break: break-all;
    animation: listScale 1s both;
  }
  @keyframes listScale {
    from {
      transform: translateY(3.375rem) scale(0.8);
      opacity: 0;
    }
    to {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }
}
</style>